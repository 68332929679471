.PaymentMethodCard {
  width: 296px;
  height: 204px;
}

.PaymentMethodCardPreview {
  border: 0 none !important;
  border-radius: 15px;
  width: 312px;
  height: 312px;
  margin: 10px 20px;
}

.BankDetailsCardPreview {
  border: 0 none !important;
  width: 306px;
  height: 134px;
}
