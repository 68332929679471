.imgPreview {
  display: block;
  max-width: 100%;
}

.btn {
  width: 49%;
  height: 67px;
  color: #fff;
  margin: 0 0.5%;
  font-size: 1.375rem;
  font-weight: 600;
  border: 0 none;
}
.btn:hover {
  background-color: var(--color-dark-blue1);
}
.btnSubmit {
  background-color: var(--color-green1);
}
