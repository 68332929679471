.content {
  max-width: 1046px;
}

.whiteBox {
  background-color: #fff;
  color: var(--color-dark-gray2);
  font-weight: 500;
  padding: 30px 0;
  text-align: center;
}

.box {
  float: left;
  height: 400px;
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
  color: var(--color-dark-gray2);
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  margin: 0 1% 10px;
  padding: 100px 0 0;
  max-width: 370px;
}

.icon {
  font-size: 8rem;
}

.imgWrapper {
  min-height: 100px;
}

.imgPreview {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.title {
  font-size: 1.125rem;
}
.text {
  font-size: 0.94rem;
  padding-top: 43px;
}

.text2 {
  color: #000;
  font-size: 1.19rem;
}

.btn {
  width: 49%;
  height: 67px;
  color: #fff;
  margin: 0 0.5%;
  font-size: 1.375rem;
  font-weight: 600;
  border: 0 none;
}
.btn:hover {
   background-color: var(--color-dark-blue1);
 }
.btnBack {
  background-color: var(--color-dark-gray2);
}
.btnSubmit {
  background-color: var(--color-green1);
}

@media (max-width: 768px) {
  .box {
    flex: 0 0 90%;
    margin: 5%;
  }
}
