.professionalClientsPage strong {
  font-weight: 500 !important;
}

.button {
  width: 102px;
  background-color: var(--color-dark-blue1);
  font-size: 0.75rem;
  margin-top: 50px;
}
