.BankDetailsCardPreview {
  border: 0 none !important;
  width: 306px;
  height: 134px;
}

.smallerText {
  color: var(--color-dark-blue1);
  font-size: 1.0625rem;
  font-weight: 500;
}

.biggerText {
  color: var(--color-dark-blue1);
  font-size: 1.375rem;
  font-weight: 500;
}
