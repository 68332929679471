.sidebarRegister {
  font-weight: 300;
  padding: 0 5px;
}

.circle {
  background-color: var(--color-orange4);
  border: 3px solid var(--color-orange5);
  border-radius: 50%;
  color: #fff;
  flex: 0 0 56px;
  height: 56px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  padding-top: 7px;
}
.circleSmallCompleted {
  border-radius: 50%;
  display: inline-block;
  background-color: var(--color-green3);
  border: 1px solid var(--color-green4);
  margin-right: 5px;
  width: 13px;
  height: 13px;
  text-align: center;
}
.circleSmallPlaceholder {
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  width: 13px;
  height: 13px;
}
.activeStep {
  background-color: var(--color-orange1);
  border: 3px solid var(--color-orange1);
}
.inactiveStep {
  color: var(--color-orange6);
}
.completedStep {
  background-color: var(--color-green3);
  border: 3px solid var(--color-green4);
  padding-top: 5px;
}
.checkmark {
  color: #fff;
  font-size: 1.4rem;
}
.checkmarkSmall {
  color: #fff;
  display: block;
  font-size: 0.4rem;
  margin: 2px auto 0;
}

.textSection {
  flex: 1;
}

.registerStep {
  font-family: "Work Sans", sans-serif;
  font-size: 1rem;
  letter-spacing: 2px;
  padding: 5px 0 2px;
}
.registerStepTitle {
  font-size: 1.125rem;
  font-weight: 400;
  padding-bottom: 10px;
}
.registerSubsteps {
  font-size: 1.06rem;
}
.registerSubsteps li {
  background-repeat: no-repeat;
  background-position: 0 5px;
  font-size: 1.06rem;
  font-weight: 400;
  padding-bottom: 1rem;
}

.inactiveSubstep,
.inactiveStepText {
  color: var(--color-dark-blue4) !important;
}

@media (max-width: 767px) {
  .sidebarRegister {
    display: none;
    /*max-width: 35%;*/
  }
  /*.registerStep {*/
  /*  padding-top: 0;*/
  /*}*/
  /*.registerStepTitle {*/
  /*  font-size: 0.8rem;*/
  /*}*/
  /*.registerSubsteps li {*/
  /*  font-size: 0.7rem;*/
  /*}*/
  /*.circle {*/
  /*  flex: 0 0 34px;*/
  /*  height: 34px;*/
  /*  font-size: 1.2rem;*/
  /*  padding-top: 1px;*/
  /*}*/
  /*.circleSmallCompleted {*/
  /*  margin-right: 2px;*/
  /*}*/
  /*.circleSmallPlaceholder {*/
  /*  !*margin-right: 2px;*!*/
  /*  display: none;*/
  /*}*/
  /*.checkmark {*/
  /*  font-size: 1.2rem;*/
  /*}*/
}
