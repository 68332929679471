/* Global CSS */
:root {
  --color-orange1: #FF5E45;
  --color-orange2: #FF7A63;
  --color-orange3: #FFA191;
  --color-orange4: #B54F48;
  --color-orange5: #7B454B;
  --color-orange6: #C87B76;
  --color-orange7: #FFB600;
  --color-light-blue1: #366899;
  --color-light-blue2: #528FCC;
  --color-light-blue3: #DEEDFC;
  --color-dark-blue1: #143352;
  --color-dark-blue2: #163859;
  --color-dark-blue3: #284D73;
  --color-dark-blue4: #4F667D;
  --color-dark-blue5: #353535;
  --color-light-gray1: #FEFEFE;
  --color-light-gray2: #F4F4F4;
  --color-light-gray3: #C4C4C4;
  --color-light-gray4: #747474;
  --color-light-gray5: #F6F6F6;
  --color-dark-gray1: #9EABB7;
  --color-dark-gray2: #757575;
  --color-dark-gray3: #D7D7D7;
  --color-dark-gray4: #818991;
  --color-green1: #1DC137;
  --color-green2: #A2E4AD;
  --color-green3: #19933F;
  --color-green4: #177046;
  --color-red: #CB2026;
}

body {
  background: var(--color-light-gray1);
  font-family: "Roboto", sans-serif;
}

.mw1200 {
  max-width: 1200px;
}

.breakWord {
  word-wrap: break-word;
}

.opacity50 {
  opacity: 0.5;
}

.greyBg {
  background: var(--color-light-gray2);
}
.blueBg {
  background: var(--color-dark-blue1);
}

.rejectedAccBg {
  background-color: #ffbbcc;
}

.fontWeightBold500 {
  font-weight: 500 !important;
}

.clientStatusBadge {
  height: 21px;
  line-height: 21px;
  color: #fff;
  font-size: 9px;
  border-radius: 50px;
  padding: 0 12px;
  margin: 4px 8px 0 0;
}
.clientStatusBadge.retail {
  background-color: var(--color-dark-blue1);
}
.clientStatusBadge.professional {
  background-color: var(--color-orange1);
}

.blueButton {
  background-color: var(--color-dark-blue1);
  border: 0 none;
  border-radius: 10px;
  float: right;
  font-size: 0.75rem;
  font-weight: bold;
  margin-top: 25px;
  width: 102px;
}
.greenButton {
  background-color: var(--color-green3);
  border: 0 none;
  border-radius: 10px;
  float: right;
  font-size: 0.75rem;
  margin-top: 25px;
  width: 102px;
}
.greyButton {
  background-color: var(var(--color-dark-gray4));
  border: 0 none;
  border-radius: 10px;
  float: right;
  font-size: 0.75rem;
  margin-top: 25px;
  width: 102px;
}
.simpleBackButton {
  color: var(--color-light-gray4);
  font-size: 0.75rem;
  margin-top: 25px;
}

.orangeRegisterBtn {
  background-color: var(--color-orange1);
  border-radius: 15px;
  color: #fff !important;
  height: 30px;
  line-height: 30px;
  font-size: 1.0625rem;
  text-align: center;
  padding: 0 10px !important;
}
.blueRegisterBtn {
  background-color: var(--color-dark-blue1);
  border-radius: 15px;
  border: 0 none;
  color: #fff !important;
  font-size: 1rem;
  width: 220px;
  height: 50px;
  line-height: 2;
  text-align: center;
}

.plainLoginBtn {
  width: 65px;
  padding: 6px 10px !important;
}

.orangeLink {
  color: var(--color-orange1);
  font-size: inherit;
  padding: 0;
  line-height: 1;
}

.image {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.custom-checkbox { z-index: 0 }
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--color-dark-blue1);
  border-color: var(--color-dark-blue1);
}
#professionalClientsPage .custom-checkbox .custom-control-input {
  width: 1.625rem;
  height: 1.625rem;
}
#professionalClientsPage .custom-checkbox .custom-control-label::before,
#professionalClientsPage .custom-checkbox .custom-control-label::after {
  width: 1.625rem;
  height: 1.625rem;
  border-width: 3px;
}

.registerPage .card-body {
  padding-left: 30px;
  padding-right: 30px;
}

.roundedBox {
  border-radius: 15px;
  overflow: hidden;
}
.topBorders {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
}
.bottomBorders {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: hidden;
}

.sidebarLogo {
  cursor: pointer;
  display: block;
  margin: 40px auto 100px;
  width: 172px;
  max-width: 90%;
}
.sidebarLogoAdmin {
  margin-bottom: 40px;
}

.applyForProfClient {
  max-width: 220px;
}

/* HEADER */
.navbar {
  background-color: #fff;
}
.navbar-light .navbar-nav .nav-link {
  color: #000;
}
.headerCategory {
  color: var(--color-dark-blue1);
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.03em;
}
.headerCircle {
  background-color: var(--color-light-gray3);
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
/* HEADER END */

/* TABS */
.nav-tabs {
  border: 0 none;
}
.nav-tabs .nav-item {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  background: var(--color-light-gray2);
  color: #000;
  font-weight: 600;
  margin-right: 5px;
  padding: .55rem 2.3rem;
}
.nav-tabs .nav-item.active {
  background-color: var(--color-orange1);
  color: #FFF;
}
.tab-pane {
  background: var(--color-light-gray2);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  overflow: hidden;
}
/* TABS END */

/* SIDEBAR */
.sidebar {
  background-color: var(--color-dark-blue1);
  max-width: 310px;
}
.sidebar.register {
  max-width: 390px;
}
.sidebar .list-group-item {
  background-color: transparent;
  color: #FFF;
}
.sidebar .list-group-item.active {
  background-color: var(--color-light-blue2) !important;
}

.sidebar .menuItem {
  background-color: transparent;
  color: #FFF;
}
.mobileNavLinks .menuItem {
  background-color: transparent;
  border: 0 none;
  width: 90%;
  text-align: left;
}
.mobileNavLinks .menuItem.active,
.mobileNavLinks .menuItem:active {
  background-color: var(--color-dark-blue1) !important;
}
.sidebar .menuItem.active {
  color: var(--color-orange1);
}
.menuItemTitle,
.menuItemTitle:hover {
  color: #A0A0A0;
  font-size: 13px;
  padding: 30px 0 10px 15%;
  text-decoration: none;
  cursor: default;
  width: 95%;
  text-align: left;
}
.sidebarUser {padding: 0 5px}
.sidebarUser a {
  display: block;
  width: 100%;
  font-size: 17px;
  text-align: left;
  border: 0 none;
  padding-left: 22%;
}
.sidebarUser a.active {
  color: var(--color-orange1) !important;
  background-color: transparent !important;
}

.mobileNavbar {
  background-color: var(--color-dark-blue1);
  height: 100vh;
  align-content: start;
}
.mobileNavbar .navbar-toggler {
  border-color: white;
  height: 40px;
}
.mobileNavbarClose {
  color: white;
  font-size: 2.7rem;
}
.mobileNavbar .menuItemTitle {
  color: white;
  font-size: 1rem;
  margin-bottom: 10px;
}
/* SIDEBAR END */

/* CARDS */
.cardWithLeftBorder {
  border-radius: 15px;
  display: flex;
  float: left;
  overflow: hidden;
}
.cardWithLeftBorder > div {
  align-self: stretch;
}
.cardBorder {
  width: 11px;
}
.cardBorder.red {
  background-color: var(--color-orange1);
}
.cardBorder.green {
  background-color: var(--color-green1);
}
.cardBorder.orange {
  background-color: var(--color-orange7);
}
.card {
  border: 0 none;
  box-shadow: none !important;
}
.cardWithShadow {
  -webkit-box-shadow: 1px 2px 6px 1px rgba(0,0,0,0.30);
  -moz-box-shadow: 1px 2px 6px 1px rgba(0,0,0,0.30);
  box-shadow: 1px 2px 6px 1px rgba(0,0,0,0.30);
}
.customCardsStyle {
  float: left;
  font-size: 0.875rem;
  /*margin: 10px 20px;*/
  /*border-left: 11px solid var(--color-orange1);*/
  /*-webkit-box-shadow: 2px 2px 12px 0 rgba(0,0,0,0.50);*/
  /*-moz-box-shadow: 2px 2px 12px 0 rgba(0,0,0,0.50);*/
  /*box-shadow: 2px 2px 12px 0 rgba(0,0,0,0.50);*/
}
.customCardsStyle .card-body {
  letter-spacing: 0.01em;
  padding: 0.55rem 0.55rem 0 0.94rem;
  font-weight: 500;
}
.cardCaptionTitle {
  color: var(--color-dark-gray2);
  font-family: "Work Sans", sans-serif;
  font-size: 0.56rem;
  font-weight: 400;
}
.cardFlag {
  border-radius: 15px;
  text-align: center;
  font-weight: 600;
  display: inline-block;
  font-family: "Work Sans", sans-serif;
}
.cardFlag.accType,
.cardFlag.accPlatform {
  display: block;
  color: #FFF;
  width: 42px;
  height: 18px;
  line-height: 18px;
  font-size: 9px;
}
.cardFlag.accTypeWizard {
  background-color: var(--color-green1);
  color: #FFF;
  float: left;
  width: 51px;
  height: 22px;
  line-height: 22px;
  font-size: 12px;
}
.accType.live {
  background-color: var(--color-green1);
  margin-bottom: 4px;
}
.accType.demo {
  background-color: var(--color-red);
  margin-bottom: 4px;
}
.accPlatform {
  background-color: var(--color-dark-blue1);
}
.cardCurrencyFlag {
  background-color: var(--color-light-blue3);
  font-size: 11px;
  width: 43px;
  height: 19px;
  line-height: 19px;
}
.customFundingMethodCardsStyle .cardCurrencyFlagFee {
  margin-top: 10px;
}
.cardCurrencyFlagFee {
  margin-top: 5px;
  margin-left: 10px;
}
.cardCurrencyFlagAmount {
  margin-top: 2px;
  margin-left: 10px;
}
.cardCurrencyFlagSmaller {
  float: right;
  font-size: 8px;
  width: 37px;
  height: 16px;
  line-height: 16px;
  margin-top: 3px;
}
.card-body .cardCurrencyFlagSmaller {
  margin-top: 14px;
  margin-left: 10px;
}
.cardButton {
  border-radius: 15px;
  border: 0 none;
}
.card-body .cardButton {
  margin-top: 7px;
  width: 76px;
  height: 25px;
  font-size: 8px;
}
.wizardAccountList .cardButton,
.card-body .platformDownload {
  width: 102px;
  height: 33px;
  line-height: 22px;
  color: #fff;
  font-size: 12px;
  margin-bottom: 10px;
}
.card-body .platformDownload:hover {
  text-decoration: none;
}
.wizardFundAmount .cardButton {
  width: 102px;
  height: 33px;
  font-size: 12px;
  margin-bottom: 10px;
}
.customFundingMethodCardsStyle .card-body,
.customCardsStyleMediumFont .card-body {
  padding: 1rem 0.6rem 0.6rem 1.5em;
}
.customFundingMethodCardsStyle {
  font-size: 1.125rem;
}
.customFundingMethodCardsStyle .cardCaptionTitle {
  font-size: 0.7rem;
}
.customFundingMethodCardsStyle .cardCaptionParagraph {
  font-size: 0.875rem;
}
.customFundingMethodCardsStyle .cardCurrencyFlagFee {
  margin-top: 10px;
}
.customCardsStyleMediumFont {
  font-size: 1rem;
}
.customCardsStyleMediumFont .cardCaptionTitle {
  font-size: 0.6875rem;
}
.customInternalTransferCardsStyle .card-body {
  padding: 2rem 0.6rem 0.6rem 1.5em;
}
.tradingProducts {
  width: 100%;
}
.tradingProducts .card-body {
  padding: 50px 0 0;
  text-align: center;
  min-height: 275px;
}


.verifiedEmail .circle {
  width: 133px;
  height: 133px;
  border-radius: 50%;
  margin: 80px auto 15px;
  text-align: center;
}
.verifiedEmail .circle.success {
  background-color: var(--color-green1);
  padding-top: 33px;
}
.verifiedEmail .checkmark {
  color: #fff;
  display: inline-block;
  font-size: 4rem;
}
.checkmarkAnimation {
  -webkit-animation: scale-up-center 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.4s both;
  animation: scale-up-center 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.4s both;
}
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
/* CARDS END */

/* TABLE */
.roundedTableWrapper .react-bootstrap-table {
  border-radius: 15px;
}
.react-bootstrap-table {
  background: var(--color-light-gray2);
  font-weight: 600;
  padding: 10px 20px;
}
.table {
  border-radius: 15px;
  font-size: 11px;
  overflow: hidden;
}
.table thead {
  background: transparent;
}
.table tbody {
  background: #fff;
}
.table th,
.table td {
  border: 0 none !important;
}
/* TABLE END */

/* CUSTOM DROPDOWNS */
.btn-primary {
  box-shadow: none !important;
}
.dropdown-menu {
  border-radius: 15px;
}
.dropdown-toggle,
.dropdown-toggle.btn-primary {
  background-color: transparent !important;
  border: 0 none !important;
  box-shadow: none !important;
  color: #000000 !important;
}
.dropdown-toggle:focus,
.dropdown-toggle:hover {
  background: transparent;
  color: #000000;
}
.dropdownFilters .dropdown {
  background-color: var(--color-light-gray2);
  border-radius: 15px;
  float: left;
}
.dropdownFilters .dropdown-toggle {
  font-weight: 600;
}
.dropdownFilters .dropdownFilterCaption {
  font-size: 11px;
  margin: 0 0 0 12px;
}
/* CUSTOM DROPDOWNS END */

#transfer-history-tabs-tabpane-deposits.tab-pane {
  overflow: unset;
}

/* PAGINATION TWEAKS */
.page-link {
  padding: 0.2rem .75rem;
}
.page-item.active .page-link {
  background-color: var(--color-dark-blue1);
  border-color: var(--color-dark-blue1);
}
.react-bs-table-sizePerPage-dropdown {
  margin-left: 10px;
}
.page-item .page-link,
.react-bs-table-sizePerPage-dropdown .dropdown-toggle {
  font-size: 0.7rem;
}
.react-bs-table-sizePerPage-dropdown .dropdown-toggle {
  border: 1px solid var(--color-dark-blue1) !important;
  margin-left: 12px;
  padding: 0.175rem 0.45rem;
}
.react-bs-table-sizePerPage-dropdown .dropdown-item {
  font-size: 0.7rem;
}
/* PAGINATION TWEAKS END */

/* WIZARD */
.wizardStep {
  background-color: var(--color-dark-gray1);
  color: #FFF;
  float: left;
  width: 56px;
  height: 56px;
  line-height: 56px;
  border-radius: 50%;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  margin-right: 20px;
}
.wizardStepHeadline {
  color: var(--color-dark-gray1);
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 0.08em;
}
.wizardStepText {
  color: var(--color-dark-gray1);
  font-size: 18px;
  font-weight: 500;
}
.wizardStepWrapper.active .wizardStep {
  background-color: var(--color-dark-blue1);
}
.wizardStepWrapper.active .wizardStepHeadline {
  color: var(--color-dark-blue1);
  font-size: 16px;
}
.wizardStepWrapper.active .wizardStepText {
  color: var(--color-dark-blue1);
  font-size: 18px;
  font-weight: 500;
}
.wizardStepWrapper.finished .wizardStep {
  background-color: var(--color-green2);
}
.wizardStepWrapper.finished .wizardStepHeadline {
  color: var(--color-green2);
  font-size: 16px;
}
.wizardStepWrapper.finished .wizardStepText {
  color: var(--color-green2);
  font-size: 18px;
  font-weight: 500;
}
.wizardStepWrapper.failed .wizardStep {
  background-color: var(--color-orange6);
}
.wizardStepsParagraph {
  font-size: 19px;
  padding: 0 0 30px 100px;
}
.wizardAccountList li {
  display: flex;
  background-color: #fff;
  /*border-left: 11px solid var(--color-orange1);*/
  border-radius: 15px;
  width: 100%;
  max-width: 1015px;
  min-height: 56px;
  margin: 20px auto;
  /*padding: 13px 10px 2px;*/
  font-weight: 500;
  overflow: hidden;
}
.wizardAccountList li > div,
.wizardAccountList li > span {
  display: inline-block;
  align-items: stretch;
}
.wizardAccountList li > a,
.wizardAccountList li > span,
.wizardAccountList li > button {
  margin: 13px 10px 2px;
}
.fundingSelectValuesWrapper {
  max-width: 788px;
}
.fundingInputValuesWrapper {
  background-color: #fff;
  border-radius: 15px;
  height: 46px;
  max-width: 788px;
  overflow: hidden;
}
.fundingInputValuesWrapper .cardCurrencyFlag {
  float: right;
  margin-top: 15px;
  margin-right: 19px;
}
.fundingInputValue {
  border: 0 none;
  float: left;
  width: 80%;
}
.wizardComplete {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
}
.wizardComplete .circle {
  width: 133px;
  height: 133px;
  border-radius: 50%;
  margin: 0 auto 15px;
}
.wizardComplete .checkmark {
  color: #fff;
  display: inline-block;
  font-size: 4rem;
}
.wizardComplete .circle.success {
  background-color: var(--color-green1);
  padding-top: 33px;
}
.wizardComplete .circle.error {
  background-color: var(--color-red);
  color: #fff;
  font-family: 'Nunito', sans-serif;
  font-size: 4rem;
  padding-top: 21px;
}
.wizardComplete .messageTitle {
  font-family: "Work Sans", sans-serif;
}
.wizardComplete .messageTitle.success {
  color: var(--color-green1);
}
.wizardComplete .messageTitle.error {
  color: var(--color-red);
}
.wizardComplete .messageText {
  max-width: 740px;
}
.wizardComplete .completeButton {
  border: 0;
  border-radius: 15px;
  width: 100%;
  height: 67px;
  max-width: 750px;
  margin-top: 80px;
  margin-bottom: 100px;
  font-size: 1.375rem;
  font-weight: 600;
}
.wizardComplete .completeButton.success {
  background-color: var(--color-green1);
}
.wizardComplete .completeButton.error {
  background-color: var(--color-dark-gray2);
}
/* WIZARD END */

/* UPLOAD */
.filepond--wrapper {
  width: 100%;
  max-width: 746px;
  height: 220px;
  margin: 0 auto;
  border: 2px dashed #A3A3A3;
}
.filepond--hopper {
  height: 100% !important;
  margin: 0;
}
.filepond--panel-root {
  background-color: #fff !important;
}
.filepond--drop-label {
  margin-top: 68px !important;
}
.filepond--label-action {
  display: block;
  width: 251px;
  height: 61px;
  line-height: 61px !important;
  background-color: var(--color-light-gray2);
  border-radius: 15px;
  border: 2px solid #A3A3A3;
  font-weight: 500;
  text-decoration: none !important;
  margin-top: 50px;
}
.filepond--credits { display: none !important }
/* UPLOAD END */

@media (max-width: 767px) {
  .sidebar {
    max-width: 35%;
  }
  .sidebarUser a {
    padding-left: 10px;
  }
  .menuItemTitle, .menuItemTitle:hover {
    font-weight: 500;
    padding-left: 5px !important;
    padding-top: 10px !important;
  }

  .registerPage .card-body {
    padding-left: 12px;
    padding-right: 12px;
  }

  .wizardStepsParagraph {
    padding-left: 10px;
  }
  .wizardAccountList li {
    flex-wrap: wrap;
  }
  .wizardAccountList li > span {
    flex: 0 0 33%;
  }
  .wizardAccountList li > span.cardFlag {
    flex: 0 0 38px;
  }
}
